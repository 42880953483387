<template>
	<div class="page">
		<van-nav-bar title="添加银行卡" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<div class="flex mt bg-white" style="align-items: center;">
			<div class="add-left">持卡人</div>
			<div class="add-right" style="line-height: 0.8rem;color: #C9C9C9;">{{cardName}}</div>
		</div>
		<div class="flex bg-white border-top" style="align-items: center;">
			<div class="add-left">卡号</div>
			<div class="add-right">
				<input type="number" class="add-right" placeholder="请输入银行卡号" v-model="cardNumber">
			</div>
		</div>
		<div class="flex bg-white border-top" style="align-items: center;">
			<div class="add-left">银行</div>
			<div class="add-right">
				<input type="text" class="add-right" placeholder="请输入银行" v-model="cardValue">
			</div>
		</div>
		<div class="flex bg-white border-top" style="justify-content: space-between;align-items: center;">
			<div class="add-left2">设为默认银行卡</div>
			<div style="margin-right: 0.4rem;">
				<van-switch v-model="switchChecked" size="16" active-color="#F15223" inactive-color="#A3A1A1" />
			</div>
		</div>
		<div class="add-btn" @click="onAdd">确认</div>





	</div>
</template>

<script>
	export default {
		data() {
			return {
				openid: 2,
				login_token: localStorage.getItem("login_token"),
				cardValue: '', //银行
				columns: ['中国银行', '中国工商银行', '中国建设银行', '中国农业银行', '中国民生银行', '中国邮政储蓄银行', '中信银行', '交通银行', '招商银行', '广发银行',
					'兴业银行', '平安银行'
				],
				showPicker: false,
				switchChecked: false, //是否设为默认
				cardName: '', //持卡人姓名
				cardNumber: '', //卡号

			}
		},
		mounted() {
			var _this = this;
			//获取持卡人姓名
			_this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});
			var myData = new URLSearchParams();
			myData.append("openid", _this.openid);
			myData.append("login_token", _this.login_token);
			_this.$http({
				method: 'post',
				url: 'users/api.finance/get_person_auth_info',
				data: myData
			}).then((res) => {
				if (res.data.code == 0) {
					_this.cardName = res.data.auth_info.real_real_name;

					const cardid = _this.$route.query.id;
					if (cardid) {
						const cardData = new URLSearchParams();
						cardData.append("openid", _this.openid);
						cardData.append("login_token", _this.login_token);
						cardData.append("id", cardid);
						_this.$http({
							method: 'post',
							url: 'users/api.finance/getUserBankInfo',
							data: cardData
						}).then((res) => {
							_this.$toast.clear();
							if (res.data.code == 0) {
								_this.cardValue=res.data.data.account_bank;
								_this.cardNumber=res.data.data.account_num;
								if(res.data.data.is_default==1){
									_this.switchChecked=true
								}else{
									_this.switchChecked=false
								}
							} else if (res.data.code == -100) {
								_this.$dialog.alert({
									title: '提示',
									message: '登录过期，请重新登录',
								}).then(() => {
									_this.$router.push({
										path: "/login"
									})
								});

							} else {
								_this.$toast(res.data.msg);
							}
						}).catch(() => {
							_this.$toast.clear();
							_this.$toast('网络请求失败');
						});


					} else {
						_this.$toast.clear();
					}

				} else if (res.data.code == -100) {
					_this.$toast.clear();
					_this.$dialog.alert({
						title: '提示',
						message: '登录过期，请重新登录',
					}).then(() => {
						_this.$router.push({
							path: "/login"
						})
					});

				} else {
					_this.$toast(res.data.msg);
				}
			}).catch(() => {
				_this.$toast.clear();
				_this.$toast('网络请求失败');
			});


		},
		methods: {
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			//确认添加
			onAdd() {
				var _this = this;
				if (_this.cardNumber == '') {
					_this.$toast("请填写银行卡号");
				} else if (_this.cardValue == '') {
					_this.$toast("请选择银行");
				} else {
					_this.$toast.loading({
						message: '加载中...',
						forbidClick: true,
						duration: 0
					});
					var myData = new URLSearchParams();
					myData.append("openid", _this.openid);
					myData.append("login_token", _this.login_token);
					myData.append("account_bank", _this.cardValue);
					myData.append("account_num", _this.cardNumber);
					if (_this.switchChecked) {
						myData.append("is_default", "1");
					}
					if(_this.$route.query.id){
						myData.append("id", _this.$route.query.id);
					}
					_this.$http({
						method: 'post',
						url: 'users/api.finance/addUserBankInfo',
						data: myData
					}).then((res) => {
						_this.$toast.clear();
						if (res.data.code == 0) {
							if(_this.$route.query.id){
								_this.$dialog.alert({
									title: '提示',
									message: '修改成功',
								}).then(() => {
									_this.$router.go(-1)
								});
							}else{
								_this.$dialog.alert({
									title: '提示',
									message: '添加成功',
								}).then(() => {
									_this.$router.go(-1)
								});
							}
							
						} else if (res.data.code == -100) {
							_this.$dialog.alert({
								title: '提示',
								message: '登录过期，请重新登录',
							}).then(() => {
								_this.$router.push({
									path: "/login"
								})
							});

						} else {
							_this.$toast(res.data.msg);
						}
					}).catch(() => {
						_this.$toast.clear();
						_this.$toast('网络请求失败');
					});
				}




			},


		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped="scoped">
	.add-btn {
		border-radius: 0.08rem;
		background: #F15223;
		height: 0.72rem;
		width: 4.4rem;
		margin: 0 auto;
		margin-top: 0.9rem;
		margin-bottom: 0.3rem;
		line-height: 0.72rem;
		font-size: 0.24rem;
		color: #FFFFFF;
		text-align: center;
	}

	.add-left2 {
		height: 1rem;
		font-size: 0.3rem;
		line-height: 1rem;
		text-indent: 0.8rem;
	}

	.border-top {
		border-top: 0.02rem solid #E5E3E3;
	}

	.bg-white {
		background-color: #FFFFFF;
	}

	.mt {
		margin-top: 0.3rem;
	}

	.page {
		height: 100%;
		background-color: #F6F6F6;
	}

	.add-right {
		width: 4.9rem;
		height: 0.8rem;
		border: none;
		outline: none;
		font-size: 0.3rem;
	}

	.add-left {
		width: 2.5rem;
		height: 1rem;
		font-size: 0.3rem;
		line-height: 1rem;
		text-indent: 0.8rem;
	}

	.flex {
		display: flex;
	}
</style>
